import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
    fetchOrganization() {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_URL + '/api/v1/website/organizations')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchBranches() {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_URL + '/api/v1/website/branches')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRecord(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/api/v1/website/preview/${params.record}/${params.preview_token}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchVoucher(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/api/v1/website/preview/receipts/${params.preview_token}/voucher`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
  modules: {
  }
})
