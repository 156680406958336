<template>
  <v-app>
    <Navbar
      v-if="$route.name == 'Home'"
      :color="color"
      :flat="flat"
      :isPromoPresent="isPromoPresent"
      v-scroll="onScroll"
    />
    <v-main class="pt-0">
      <router-view @promoUpdate="promoUpdate" />
    </v-main>
    <Footer v-if="$route.name == 'Home'" />
    <ChatWidget v-if="$route.name == 'Home'" />
  </v-app>
</template>

<script>
import Navbar from '@/components/common/Navbar'
import Footer from '@/components/common/Footer'
import ChatWidget from '@/components/common/ChatWidget'

export default {
  name: 'App',
  components: {
    Navbar,
    Footer,
    ChatWidget,
  },
  data: () => ({
    color: "",
    flat: null,
    isPromoPresent: false,
  }),
  created() {
    const top = window.pageYOffset || 0;
    if (top <= 60) {
      this.color = "transparent";
      this.flat = true;
    }
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      if (top > 60) {
        this.color = "ngBlue";
        this.flat = false;
      } else {
        this.color = "transparent";
        this.flat = true;
      }
    },
    promoUpdate(banners){
      if (banners.length !== 0) {
        this.isPromoPresent = true
      }
      this.scrollToSection()
    },
    scrollToSection() {
      setTimeout(() => {
        if ((this.$route.fullPath != '/') && (this.$route.name == 'Home')) {
          this.$vuetify.goTo(this.$route.fullPath.slice(1))
        }
      }, 200)
    }
  },
}
</script>
