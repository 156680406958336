import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import("../views/Home.vue")
  },
  {
    path: '/invoice/:preview_token',
    name: 'invoice-preview',
    component: () => import('@/views/preview/InvoicePreview.vue')
  },
  {
    path: '/quotation/:preview_token',
    name: 'quotation-preview',
    component: () => import('@/views/preview/QuotationPreview.vue')
  },
  {
    path: '/receipt/:preview_token',
    name: 'receipt-preview',
    component: () => import('@/views/preview/ReceiptPreview.vue')
  },
]

export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
