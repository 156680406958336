<template>
  <div class="floating-widget">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-top="290"
      transition="slide-y-reverse-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="waGreen"
          elevation="3"
          dark
          fab
          large
          v-bind="attrs"
          v-on="on"
        >
          <v-icon v-if="menu">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-whatsapp
          </v-icon>
        </v-btn>
      </template>
      <v-card width="400px">
        <div class="pa-3 d-flex waGreen white--text">
          <v-icon
            x-large
            color="white"
            class="pr-3 mx-2"
          >
            mdi-whatsapp
          </v-icon>
          <div class="text-left">
            <div class="body-1 mb-1">
              Request a quote!
            </div>
            <div class="caption">
              Hi! Click on any of the number below to ask for a quotation from our respective branches.
            </div>
          </div>
        </div>
        <div class="pa-6 text-left">
          <div
            class="pl-4 py-3 mb-3 d-flex borderLeft hover-pointer"
            @click="waChat(`https://api.whatsapp.com/send?phone=${ctaNumber}`)"
          >
            <div>
              <div class="body-2 font-weight-bold">
                Bandar/Temburong
              </div>
              <div class="caption">
                +673 891 1088
              </div>
            </div>
            <v-spacer></v-spacer>
            <v-icon
              color="waGreen"
              class="mr-3"
              
            >
              mdi-send
            </v-icon>
          </div>
          <div
            class="pl-4 py-3 d-flex borderLeft hover-pointer"
            @click="waChat(`https://api.whatsapp.com/send?phone=${ctaNumber2}`)"
          >
            <div>
              <div class="body-2 font-weight-bold">
                Tutong/Belait
              </div>
              <div class="caption">
                +673 888 1088
              </div>
            </div>
            <v-spacer></v-spacer>
            <v-icon
              color="waGreen"
              class="mr-3"
            >
              mdi-send
            </v-icon>
          </div>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'ChatWidget',
  data: () => ({
    menu: false,
    ctaNumber: process.env.VUE_APP_CTA_NUM,
    ctaNumber2: process.env.VUE_APP_CTA_NUM_2,
  }),
  methods: {
    waChat(url) {
      window.open(url, '_blank')
    }
  },
}
</script>

<style>
.floating-widget {
  position: fixed !important;
  right: 20px;
  bottom: 20px;
  text-align: right;
}
.v-menu__content {
  max-width: 90% !important;
}
.caption {
  line-height: 1.5 !important;
}
.borderLeft {
  border-left: 2px solid #25D366 !important;
  background-color: #F5F5F5;
}
</style>