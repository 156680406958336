import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        ngBlue: '#54A3DC',
        ngOrange: '#F05A23',
        ngPurple: '#66447E',
        waGreen: '#25D366',
      }
    }
  }
});
